import * as fs from 'fs';
import * as scanner from './scanner';
import * as parser from './parser';
import {Token, StmtType, Stmt} from './types';
import * as environment from './environment';

export function Run(source: string) : void {

    const tokens: Token[] = scanner.scanTokens(source);

    const stmts : Stmt[] = parser.parse(tokens);

    environment.initMMIX()

    environment.assembleProgram(stmts)

    environment.execute()
}