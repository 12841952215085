import {Bars3Icon, Cog6ToothIcon, PlayIcon} from "@heroicons/react/24/outline";
import {PauseIcon, StopIcon} from "@heroicons/react/24/outline";

function Header({save, run, isMobile, openMobileSideNav}) {

  return (
    <div className='flex flex-row h-14 border-b-2 border-black w-full '>
      <div className='flex ml-3 gap-3'>
        <button onClick={() => save()}>File</button>
        <button>Edit</button>
        <button>View</button>
      </div>
      <div className='flex ml-4 gap-1'>
        <button onClick={() => run()}>
          <PlayIcon className='w-6'/>
        </button>
        <button>
          <StopIcon className='w-6' />
        </button>
        <button>
          <Cog6ToothIcon className='w-6' />
        </button>
      </div>
      <div className='flex w-1/2 justify-end mr-2'>
        {isMobile ? (
            <button onClick={() => openMobileSideNav()}>
              <Bars3Icon className='w-6'/>
            </button>
          )
          :
          <div></div>
        }
      </div>
    </div>
  )
}

export default Header