import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import FrontPage from "./pages/FrontPage";
import NotFound from "./pages/NotFound";
import Editor from "./pages/Editor";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage/>}/>
        <Route path='/editor' element={<Editor />}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;