import AceEditor from 'react-ace';

function TextEditor({code, setCode}) {
  return (
    <div className='w-full h-full overflow-y-hidden'>
      <AceEditor
        isolation={'isolate'}
        z-index={0}
        height={'100vh'}
        width={'100vh'}
        showPrintMargin={true}
        wrapEnabled
        fontSize={16}
        value={code}
        onChange={setCode}
      />
    </div>
  );
}

export default TextEditor;