function Console({data}) {
  return (
    <div className='flex flex-col p-6 gap-1 h-full overflow-y-scroll'>
      {data.map((line, index) => (
        <div key={index}>{line.text}</div>
      ))}
    </div>
  )
}

export default Console;