import {useState} from "react";

const menuItems = [
  {
    title: 'Documentation',
    link: 'Github'
  }
]

function SideNav() {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    if (!open) {
      setOpen(true)
    }
  }

  return (
    <div className='flex border-r-2 border-black' >
      <div className={
        `h-screen  pt-6 ${
          open ? "w-72" : "w-0"
        } 
        duration-500 relative`
      }>

        <div className={
          `pl-5 ${open ? "visible" : "invisible"}`
        }>
          <a href='/'>
            <img src='https://i.imgur.com/AXL1z8B.png' alt='WebMMIX logo' />
          </a>
        </div>

        {/*
        <div className={
          `p-5 pt-8 ${open ? "scale-x-100" : "scale-x-0"}`
        }>
          {menuItems.map((item) => (
            <a href={item.link}>
              <div className=''>{item.title}</div>
            </a>
          ))}
        </div>
        */}

      </div>

      <div onClick={() => setOpen(!open)}  className='flex flex-col w-4 ml-1 justify-center'>
        <div className='h-16 w-3 rounded-full border-2 border-black'></div>
      </div>
    </div>
  )
}

export default SideNav