import {useState} from "react";

function Tabs({children}) {
  let [tab, setTab] = useState(0)

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex w-full h-12'>
        <div onClick={() => setTab(0)}
             className={`
             flex-grow
             border-black
             ${tab === 1 ? 'border-b-2' : ''}
             `}
        >

          <div className='p-3'>Registers</div>
        </div>

        <div onClick={() => setTab(1)}
             className={`
             flex-grow
             border-black
             border-l-2
             ${tab === 0 ? 'border-b-2' : ''}
             `}
        >

          <div className='p-3'>Memory</div>
        </div>
      </div>
      <div className='container-snap m-6 p-2 overflow-y-scroll'>
        {children[tab]}
      </div>
    </div>
  )
}

function RegTable({regs}) {
  return (
    <table className='m-2 mt-4 w-fit'>
      {regs.map((reg) => (
        <tr>
          <td className='text-l border-black h-8 border-r-2 w-16'>{reg.number}</td>
          <td className='pl-12'>{reg.value.toString()}</td>
        </tr>
      ))}
    </table>
  )
}

function MemoryTable({memory}) {
  return (
    <table className='m-2 mt-4 w-fit'>
      {memory.map((byte) => (
        <tr>
          <td className='text-l border-black h-8 border-r-2 w-16'>M<sub>{byte.number}</sub></td>
          <td className='pl-12'>{byte.value.toString()}</td>
        </tr>
      ))}
    </table>
  )
}

function Debugger({regs, memory, isMobile}) {

  return (
    <div className='w-full h-full'>
        <Tabs>
          <RegTable regs={regs}/>
          <MemoryTable memory={memory} className='w-full'/>
        </Tabs>
    </div>
  )
}

export default Debugger;