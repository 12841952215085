import {useState} from "react";

const WindowsDesktop = ({children}) => {

  const [windowWidth, setWindowWidth] = useState({
    paneA: '70%',
    paneB: (98 - 70) + '%'
  });

  const [windowHeight, setWindowHeight] = useState({
    paneA: '49.5%',
    paneB: (98 - 49.5) + '%'
  });

  const handleDragX = (e) => {
    document.onselectstart = () => {
      window.getSelection().removeAllRanges();
    };

    if(e.clientX === 0)
      return;

    setWindowWidth({
      paneA: ((e.clientX/window.innerWidth)*100) + "%",
      paneB: 99 - ((e.clientX/window.innerWidth)*100) + "%"
    })
  }

  const handleDragY = (e) => {
    document.onselectstart = () => {
      window.getSelection().removeAllRanges();
    };

    if(e.clientY === 0)
      return;

    setWindowHeight({
      paneA: ((e.clientY/window.innerHeight)*100) + "%",
      paneB: 99 - ((e.clientY/window.innerHeight)*100) + "%"
    })
  }

  return (
    <div className='flex flex-row w-full p-4 h-[90vh]'>
      <div style={{width: `${windowWidth.paneA}`}}>
        <div style={{height: `${windowHeight.paneA}`}} className={` border-2 border-black`}>
          {children[0]}
        </div>
        <div className='h-[2vh] cursor-ns-resize' onDrag={handleDragY}></div>
        <div style={{height: `${windowHeight.paneB}`}}  className={`border-2 border-black`}>
          {children[1]}
        </div>
      </div>
      <div className='w-[2vh] cursor-ew-resize' onDrag={handleDragX}></div>
      <div style={{width: `${windowWidth.paneB}`}} className={`h-full border-2 border-black`}>
        {children[2]}
      </div>
    </div>
  );
};

export default WindowsDesktop;