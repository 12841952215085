import SideNav from "../components/SideNav";
import Header from "../components/Header";
import WindowsDesktop from "../components/WindowsDesktop";
import TextEditor from "../components/TextEditor";
import {useEffect, useState} from "react";
import Debugger from "../components/Debugger";
import Console from "../components/Console";
import {Run} from "../MMIX/mmix";
import {Memory, Registers} from "../MMIX/environment";
import WindowsMobile from "../components/WindowsMobile";

function Editor() {

  const [code, setCode] = useState('// Labels\n' +
    'x   IS    3\n' +
    'y   IS    $1\n' +
    '\n' +
    '/* \n' +
    '    Instructions are assembled and \n' +
    '    stored starting at byte M[64]\n' +
    '*/\n' +
    '\t  LOC\t#64\n' +
    'DO    ADD   y, $1, 3+2*x\n' +
    '\t  SUB   $2,  $1, 1\n' +
    '\t  MUL   $3,  $2, 2\n' +
    '\t  JMP   END\n' +
    '\t  \n' +
    '\t  ADD   $1,  $1, 3+2\n' +
    '\t  ADD   $4,  $1, 2\n' +
    '\n' +
    'START ADD   $4,  $1, 2\n' +
    '\t  JMP   DO\n' +
    '\n' +
    'END\t  TRAP  0, HALT, 0\n' +
    '\n' +
    'MAIN  IS    START\n'
  )

  const saveCodeToFile = () => {
    let a = document.createElement("a");
    let file = new Blob([code], {type: 'text/plain'});
    a.href = URL.createObjectURL(file);

    let d = new Date;

    a.download = 'webmmix_' + d.toLocaleString().replace(", ", "_") + '.mms';
    a.click();
  }

  const createRegisterData = (i, reg) => {
    let newRegisters = [];
    for(let i = 0; i < Registers.length; i++) {
      newRegisters.push({ number: `\$${i}`, value: Registers[i]});
    }

    return newRegisters;
  }

  const createMemoryData = () => {
    let newMemory = [];
    for(let i = 0; i < Memory.length; i++) {
      newMemory.push({ number: i, value: Memory[i]});
    }

    return newMemory;
  }

  const [regs, setRegs] = useState(createRegisterData())

  const [memory, setMemory] = useState(createMemoryData())

  const [output, setOutput] = useState([
    {
      text: ''
    }
  ])

  const RunCode = () => {
    Run(code)

    setRegs(createRegisterData())
    setMemory(createMemoryData())

    setOutput(output.concat({text: 'Output will go here once the relevant instructions are implemented'}))
  }

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768

  return (
    <div className='flex'>
      {!isMobile ? <SideNav /> : <div></div>}
      <div className='flex-col w-full'>
        <Header save={saveCodeToFile} run={RunCode} isMobile={isMobile}/>
        <div>
          {!isMobile ?
            <WindowsDesktop>
              <TextEditor code={code} setCode={setCode} />
              <Console data={output}/>
              <Debugger regs={regs} memory={memory} isMobile={isMobile}/>
            </WindowsDesktop>
            :
            <WindowsMobile>
              <TextEditor code={code} setCode={setCode} />
              <Debugger regs={regs} memory={memory} isMobile={isMobile}/>
              <Console data={output}/>
            </WindowsMobile>
          }
        </div>
      </div>
    </div>
  )
}

export default Editor