import {useState} from "react";

function Tabs({children}) {
  let [tab, setTab] = useState(0)

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex w-full h-12'>
        <div onClick={() => setTab(0)}
             className={`
             flex-grow
             border-black
             ${tab !== 0 ? 'border-b-2' : ''}
             `}
        >
          <div className='p-3'>Editor</div>
        </div>

        <div onClick={() => setTab(1)}
             className={`
             flex-grow
             border-black
             border-l-2
             border-r-2
             border-b-2
             ${tab !== 1 ? 'border-b-2' : ''}
             `}
        >
          <div className='p-3'>Debugger</div>
        </div>

        <div onClick={() => setTab(2)}
             className={`
             flex-grow
             border-black
             ${tab !== 2 ? 'border-b-2' : ''}
             `}
        >
          <div className='p-3'>Output</div>
        </div>
      </div>
      <div className='container-snap overflow-y-scroll'>
        {children[tab]}
      </div>
    </div>
  )
}

function WindowsMobile({children}) {
  return (
    <div>
      <Tabs>
        {children}
      </Tabs>
    </div>
  )
}

export default  WindowsMobile;