import {useState} from "react";

const defaultUpdates = [
  {
    version: '0.0.2',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit. \n' +
      'In voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n' +
      'Excepteur sint occaecat cupidatat.\n' +
      'Ut enim ad minim veniam\n'
  },
  {
    version: '0.0.1',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit. \n' +
      'In voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n' +
      'Excepteur sint occaecat cupidatat.\n' +
      'Ut enim ad minim veniam\n' +
      '  {\n' +
      '    version: \'0.0.1\',\n' +
      '    text: \'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit. \\n\' +\n' +
      '      \'In voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\\n\' +\n' +
      '      \'Excepteur sint occaecat cupidatat.\\n\' +\n' +
      '      \'Ut enim ad minim veniam\\n\'\n' +
      '  }'
  }
]

function FrontPage() {
  let [updates, setUpdates] = useState(defaultUpdates)

  return (
    <div className='flex flex-row justify-center  h-fit'>
      <div className='py-20 p-10 md:w-3/4 md:border-l-2 md:border-r-2 border-black'>
        <div className=''>
          <div className='flex flex-col'>
            <img src='https://i.imgur.com/AXL1z8B.png' alt='WebMMIX logo'/>
            <div className="flex-1 mt-2 border-t-2 border-black"></div>
          </div>

          <div className='mt-16 flex justify-end'>
            <p className='md:w-1/2 font-bold text-right'>
              A Browser Based Study Aid for Donald Knuth’s The Art of Computer Programming
            </p>
          </div>

          <div className='flex justify-evenly mt-10'>
            <a href='/editor' className='text-center w-32 rounded-xl border-2 border-black'>
              <div className='p-2'>Get Started</div>
            </a>

            <a className='text-center  rounded-xl border-2 border-black'>
              <div className='p-2'>Documentation</div>
            </a>
          </div>

          <div className='md:ml-16 md:mr-16 mt-16'>
            <div className='ml-4 m'>
              <p className='text-xl font-bold'>CHANGELOG</p>
            </div>
            <div className='overflow-y-auto border-black border-l-2 border-r-2 h-96 '>
              {updates.map((update) => (
                <div className='mt-2 pl-6 pr-6'>
                  <p className='pr-4 text-l font-bold text-right '>{update.version}</p>
                  <p>{update.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrontPage;